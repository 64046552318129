$white: #ffffff;
$pale-grey: #f2f5f6;
$light-grey: #f7f8f8;
$pale-grey-two: #ecf0f2;
$greyish: #aeaeae;
$black: #000000;
$pinkish-grey: #bbbbbb;
$charcoal-grey: #3c4646;
$battleship-grey: #6d7777;
$lipstick: #e71d32;
$lipstick-two: #e12436;
$cool-grey: #b6b8ba;
$gunmetal: #5a6464;
$greyish-brown: #505050;
$warm-grey: #999999;
$greyish-brown-two: #575757;
$silver: #cacbcd;
$pinkish-grey-two: #c1c1c1;
$lipstick-three: #e8182b;
$blue-green: #109784;
$pinkish-grey-three: #cfcfcf;
$greyish-two: #b3b3b3;
$blue-green-two: #119784;
$pastel-red: #d85656;
