.offers {
  padding: 95px 0;
  background: #fcfcfc;
  @include r(767) {
    padding-bottom: 60px; }
  &--none {
    display: none; }
  &__block {
    height: 100%;
    a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      &:hover {
        p {
          color: $lipstick-three; } }
      img {
        max-width: 100%;
        width: 100%;
        max-height: 100%;
        height: 100%; }
      p {
        font-size: 16px;
        font-weight: 500;
        color: $greyish-brown-two;
        padding-bottom: 40px;
        transition: all 0.3s;
        max-width: 210px;
        padding-top: 20px;
        @include r(767) {
          max-width: 125px; } } } }
  &__btn {
    padding-top: 40px;
    padding-bottom: 90px;
    @include r(767) {
      padding-bottom: 20px; }
    a {
      color: $lipstick-three;
      font-size: 20.1px;
      font-weight: 500;
      display: block;
      padding-bottom: 15px; }
    i {
      font-size: 26.2px;
      color: $lipstick-three; } }
  &__img {
    background: url('../img/offers--bg.png') no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    height: 555px;
    max-width: 1920px;
    margin: 0 auto;
    .row {
      height: auto; }
    &__block {
      display: flex;
      align-items: center;
      width: 252px;
      height: 60px;
      background: $lipstick-three;
      padding: 15px 35px;
      padding-left: 15px;
      position: relative;
      margin-top: 262px;
      @include r(1024) {
        margin-top: 0;
        left: 50%;
        transform: translate(-50%);
        margin-left: -122px; }
      &--1 {
        left: 112px;
        @include r(1024) {
          margin-top: 134px; } }
      &--2 {
        left: 57px;
        @include r(1024) {
          margin-top: 68px; } }
      &--3 {
        right: -2px;
        @include r(1024) {
          margin-top: 69px; } }
      i {
        padding: 6px;
        background: $white;
        font-size: 14px;
        color: $pastel-red;
        display: inline-block;
        margin-right: 12px; }
      span {
        font-size: 34.3px;
        font-weight: 500;
        color: $white;
        padding-right: 5px; }
      p {
        font-size: 14.8px;
        font-weight: 500;
        color: $white; } } } }
