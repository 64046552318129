@font-face {
  font-family: "iconfont";
  src: url('../fonts/iconfont.eot');
  src: url('../fonts/iconfont.eot?#iefix') format('eot'),
    url('../fonts/iconfont.woff2') format('woff2'),
    url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

$icon-facebook: "\EA01";
$icon-insta: "\EA02";


%icon {
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  text-rendering: auto;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  @extend %icon;
  display: inline-block;
}

.icon-facebook:before { content: $icon-facebook; }
.icon-insta:before { content: $icon-insta; }
