.bid {
  padding-top: 100px;
  padding-bottom: 100px;
  @include r(767) {
    padding-top: 200px; }
  h3 {
    font-size: 45px;
    color: #3c4646;
    text-transform: uppercase;
    padding-bottom: 30px; }
  p {
    font-size: 16px;
    color: #7b7b7b; } }
