.reviews {
  background: rgba(255, 255, 255, 0.75);
  padding-bottom: 130px;
  padding-top: 70px;
  @include r(767) {
    padding: 30px 50px 30px 50px; }
  @include r(400) {
    padding: 40px 0;
    padding-bottom: 10px; }
  &--none {
    display: none; }
  &__block {
    padding: 35px;
    border-radius: 5px;
    background-color: $pale-grey-two;
    position: relative;
    @include r(1024) {
      padding: 30px; }
    @include r(767) {
      margin-bottom: 45px; }
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 40px solid $pale-grey-two;
      position: absolute;
      bottom: -20px;
      left: 20px;
      transform: rotate(20deg); }
    i {
      color: $lipstick-two;
      font-size: 26px; }
    h4 {
      font-size: 26px;
      font-weight: 500;
      padding: 25px 0;
      color: $charcoal-grey; }
    p {
      font-size: 14px;
      font-weight: 500;
      padding-right: 40px;
      color: $battleship-grey; }
    span {
      text-align: right;
      display: block;
      font-style: italic;
      font-size: 16px;
      font-weight: 500;
      color: $battleship-grey;
      padding-top: 30px; } }
  &__btn {
    padding-top: 40px;
    padding-bottom: 90px;
    display: none;
    @include r(767) {
      padding-bottom: 40px;
      padding-top: 0;
      display: block; }
    a {
      color: $lipstick-three;
      font-size: 20.1px;
      font-weight: 500;
      display: block;
      padding-bottom: 15px; }
    i {
      font-size: 26.2px;
      color: $lipstick-three; } } }
