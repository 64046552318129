.contacts {
  background: url('../img/contacts--bg.png') no-repeat;
  background-size: cover;
  border-top: 1px solid $blue-green;
  padding-bottom: 100px;
  @include r(767) {
    padding-top: 30px;
    border-top: 1px solid $lipstick;
    padding-bottom: 0; }
  &--mobile {
    display: flex;
    justify-content: center;
    position: relative;
    top: -55px;
    display: none;
    @include r(767) {
      display: none; }
    a {
      padding: 15px 17px;
      background: $lipstick;
      display: inline-block;
      color: $white;
      font-size: 16px;
      line-height: 1.07;
      border: 1px solid $lipstick-two;
      i {
        padding-right: 10px; } } }
  &__call {
    padding: 20px 15px;
    padding-left: 35px;
    background-image: linear-gradient(to left, #5cc4b6, #0f917f);
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
    top: -55px;
    @include r(767) {
      display: none; }
    i.fa-question-circle {
      font-size: 26px;
      color: $white;
      margin-right: 25px;
      transition: all 0.3s; }
    p {
      font-size: 26px;
      font-weight: 500;
      letter-spacing: 0.7px;
      color: $white;
      padding-right: 50px; }
    &__btn {
      position: relative;
      max-width: 450px;
      width: 100%;
      input[type='tel'] {
        background: $white;
        padding: 20px;
        font-size: 26px;
        border-radius: 5px;
        width: 100%;
        font-family: 'CrashNumberingGothic';
        color: $pinkish-grey-two; }
      input[type='submit'] {
        display: none; }
      label {
        cursor: pointer;
        &:hover {
          i {
            color: $blue-green; } }
        i {
          color: $lipstick-two;
          font-size: 26px;
          position: absolute;
          right: 20px;
          top: 20px;
          transition: all 0.3s; } } } }
  &__block {
    padding-left: 35px;
    padding-right: 10px;
    @include r(767) {
      padding: 0 10px; }
    .col-xs-6.col-sm-12.col-xs-6 {
      @include r(440) {
        max-width: 100%;
        flex-basis: 100%; } }
    &__logo {
      padding-bottom: 50px;
      h2 {
        font-family: 'Informal011 BT';
        color: $greyish-brown;
        font-size: 30.2px;
        font-weight: 900;
        line-height: 0.98;
        letter-spacing: 0.9px;
        position: relative;
        display: inline-block;
        span {
          color: $warm-grey; }
        p {
          font-size: 7.7px;
          font-weight: 500;
          color: $greyish-two;
          letter-spacing: 0.2px;
          position: absolute;
          right: 5px; } } }
    &__phon {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-left: 45px;
      padding-bottom: 30px;
      border-bottom: 1px solid $blue-green;
      @include r(767) {
        padding-bottom: 5px;
        border-right: 1px solid $blue-green;
        border-bottom: none; }
      @include r(565) {
        padding-bottom: 18px; }
      @include r(440) {
        border: none; }
      i {
        position: absolute;
        color: $blue-green;
        font-size: 26px;
        left: 0px;
        top: 4px; }
      a {
        font-weight: 500;
        color: $charcoal-grey;
        font-size: 20px;
        @include r(767) {
          font-size: 16px !important; }
        &:hover {
          color: $blue-green; } }
      a:nth-child(2) {
        font-size: 20px;
        font-weight: 300;
        color: $gunmetal;
        padding-top: 5px;
        &:hover {
          color: $blue-green; } } }
    &__location {
      position: relative;
      padding-left: 45px;
      margin-top: 30px;
      padding-bottom: 35px;
      @include r(767) {
        margin: 0; }
      i {
        position: absolute;
        color: $blue-green;
        font-size: 28px;
        left: 0px;
        top: 4px;
        @include r(767) {
          left: 7px; }
        @include r(440) {
          left: 0; } }
      a {
        font-size: 20px;
        color: $charcoal-grey;
        font-weight: 500;
        @include r(767) {
          font-size: 16px; }
        &:hover {
          color: $blue-green;
          p {
            color: $blue-green; } }
        p {
          color: $gunmetal;
          padding-top: 5px;
          transition: all 0.3s;
          font-weight: 300; } } }
    &__social {
      padding-left: 45px;
      @include r(767) {
        position: relative;
        top: -25px;
        padding-left: 45px; }
      @include r(565) {
        top: -43px; }
      @include r(440) {
        top: -15px;
        padding-bottom: 20px; }
      i {
        color: $greyish;
        font-size: 26px;
        padding-right: 15px;
        display: inline;
        transition: all 0.3s;
        &:hover {
          color: $blue-green; } } }
    form {
      input {
        font-size: 15.7px;
        font-weight: 500;
        color: $greyish;
        line-height: 1.07;
        text-transform: uppercase;
        border-radius: 5px;
        border: solid 1px $pale-grey-two;
        background: $pale-grey;
        padding: 20px 25px;
        @include r(767) {
          margin-bottom: 20px; } }
      input[type="text"] {
        width: calc(50% - 5px);
        margin-right: 10px;
        @include r(767) {
          width: 100%; } }
      input[type="email"] {
        width: calc(50% - 5px);
        @include r(767) {
          width: 100%; } }
      textarea {
        font-size: 15.7px;
        font-weight: 500;
        color: $greyish;
        line-height: 1.07;
        text-transform: uppercase;
        border-radius: 5px;
        border: solid 1px $pale-grey-two;
        background: $pale-grey;
        padding: 20px 25px;
        width: 100%;
        height: 180px;
        margin-top: 10px;
        margin-bottom: 25px;
        resize: none;
        @include r(1024) {
          width: 100%; } }
      input[type="submit"] {
        padding: 15px 17px;
        background: $lipstick;
        display: inline-block;
        color: $white;
        font-size: 15px;
        line-height: 1.07;
        border: 1px solid $lipstick-two;
        transition: all 0.3s;
        -webkit-appearance: none;
        &:hover {
          background: none;
          color: $lipstick-two; } }
      .form__btn {
        display: flex;
        justify-content: flex-end;
        @include r(767) {
          justify-content: center; } } } } }
