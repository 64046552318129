.icon-facebook {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-insta {
  width: 1em;
  height: 1em;
  fill: initial;
}
