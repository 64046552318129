
.menu--bg {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 998;
  display: none; }
.menu__mobile {
  position: fixed;
  left: 0;
  width: 250px;
  height: 100%;
  display: none;
  background: $white;
  z-index: 999;
  top: 0;
  box-shadow: 5px 0px 15px 0 #000000;
  ul {
    padding: 40px;
    padding-left: 50px;
    padding-top: 60px;
    li {
      font-size: 23px;
      padding-bottom: 20px;
      a {
        color: $black; } } }
  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    span {
      width: 0;
      height: 4px;
      background: $black;
      display: block;
      transition: color 0.3s;
      transition: width 0.5s ease;
      border-radius: 20%;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        width: 35px;
        height: 4px;
        background: $black;
        transition: color 0.3s;
        transition: transform 0.5s;
        top: 14px;
        border-radius: 20%;
        transform: rotate(225deg); }
      &:after {
        content: '';
        transform: rotate(315deg);
        position: absolute;
        left: 0;
        bottom: 22px;
        width: 35px;
        height: 4px;
        background: $black;
        transition: color 0.3s;
        transition: transform 0.5s;
        border-radius: 20%; } } } }
