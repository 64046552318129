
/* This stylesheet generated by Transfonter (https://transfonter.org) on April 17, 2018 5:13 AM */

@font-face {
    font-family: 'Informal011 BT';
    src: url('../fonts/Informal011BT-Roman.woff2') format('woff2'),
        url('../fonts/Informal011BT-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* This stylesheet generated by Transfonter (https://transfonter.org) on April 17, 2018 12:55 PM */

@font-face {
    font-family: 'CrashNumberingGothic';
    src: url('../fonts/CrashNumberingGothic.woff2') format('woff2'),
        url('../fonts/CrashNumberingGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
