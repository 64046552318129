.examples {
  @include r(767) {
    padding: 0 40px; }
  &--none {
    display: none;
    margin-bottom: 50px; }
  &__block {
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 470px;
    margin-bottom: 15px;
    @include r(1024) {
      min-height: 440px; }
    @include r(767) {
      min-height: auto; }
    a {
      display: block;
      padding: 175px 30px 90px 35px;
      @include r(1024) {
        padding: 125px 15px 60px 35px; }
      @include r(767) {
        padding: 25px; } }
    h3 {
      font-size: 32px;
      font-weight: 500;
      color: $white;
      letter-spacing: 0.8px;
      position: relative;
      @include r(767) {
        padding-right: 100px; }
      &:before {
        content: '';
        position: absolute;
        left: -10px;
        top: 6px;
        height: 62px;
        width: 5px;
        background: $lipstick; } }
    span {
      display: block;
      color: $pinkish-grey;
      font-size: 32px;
      font-weight: 500;
      letter-spacing: 0.8px; }
    p {
      font-size: 18px;
      font-weight: 500;
      color: $white;
      padding-top: 30px; } }
  &__btn {
    padding-top: 40px;
    padding-bottom: 90px;
    @include r(767) {
      padding-bottom: 40px; }
    a {
      color: $lipstick-three;
      font-size: 20.1px;
      font-weight: 500;
      display: block;
      padding-bottom: 15px; }
    i {
      font-size: 26.2px;
      color: $lipstick-three; } } }
