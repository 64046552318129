.intro {
  background: url('../img/intro--bg.png') no-repeat;
  background-size: cover;
  padding-top: 340px;
  position: relative;
  @include r(1024) {
    padding-top: 200px; }
  @include r(767) {
    padding-top: 160px; }
  &__video {
    width: 100%;
    height: 100%;
    z-index: 8;
    overflow: hidden;
    position: absolute;
    top: 0;
    video {
      height: auto;
      width: auto;
      min-height: 100%;
      min-width: 100%; } }
  &__logo {
    display: flex;
    justify-content: center;
    z-index: 9;
    position: relative;
    @include r(767) {
      display: none; }
    h2 {
      font-family: 'Informal011 BT';
      color: $lipstick-two;
      font-size: 37.5px;
      font-weight: 900;
      line-height: 0.98;
      letter-spacing: 0.9px;
      position: relative;
      display: inline-block;
      span {
        color: $white; } }
    img {
      max-width: 360px;
      width: 100%;
      @include r(1024) {
        display: none; } } }
  &__text {
    padding-bottom: 300px;
    padding-top: 60px;
    position: relative;
    z-index: 9;
    @include r(1024) {
      padding-bottom: 150px; }
    @include r(767) {
      padding-bottom: 90px; }
    &--mobile {
      margin-top: 30px;
      display: none;
      @include r(767) {
        display: block; }
      a {
        padding: 15px 17px;
        background: $lipstick;
        display: inline-block;
        color: $white;
        font-size: 16px;
        line-height: 1.07;
        border: 1px solid $lipstick-two;
        i {
          padding-right: 10px; } } }
    h1 {
      font-size: 64.1px;
      font-weight: 500;
      text-transform: uppercase;
      color: $white;
      padding-bottom: 40px;
      @include r(767) {
        font-size: 36px;
        padding-bottom: 15px; } }
    p {
      font-size: 20px;
      font-weight: 500;
      color: $white;
      @include r(767) {
        font-size: 16px;
        padding: 0 40px; }
      @include r(360) {
        padding: 0; } } }
  &__btn {
    position: relative;
    z-index: 9;
    @include r(1024) {
      padding: 0 110px; }
    @include r(767) {
      padding: 0 70px; }
    @include r(400) {
      padding: 0; }
    a {
      padding: 22px 55px;
      padding-left: 90px;
      width: 100%;
      font-size: 25.3px;
      color: $white;
      text-transform: uppercase;
      display: inline-block;
      background: rgba(0, 0, 0, 0.5);
      position: relative;
      margin: 0 7px;
      @include r(1024) {
        margin-bottom: 10px; }
      @include r(767) {
        font-size: 15px;
        padding: 15px 0;
        padding-left: 60px;
        margin: 0;
        margin-bottom: 10px; }
      &:before {
        content: '';
        position: absolute;
        bottom: -9px;
        width: 100%;
        height: 10px;
        background: $lipstick;
        left: 0;
        @include r(1024) {
          display: none; } }
      i {
        position: absolute;
        left: 25px;
        top: 35px;
        font-size: 32px;
        color: $lipstick;
        @include r(1024) {
          top: 22px; }
        @include r(767) {
          font-size: 23px;
          left: 20px;
          top: 10px; } } }
    &--last {
      margin-bottom: 0 !important;
      &:before {
        @include r(1024) {
          display: block !important; } } } } }
